import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route} from "react-router-dom";
import {createMuiTheme, CssBaseline, MuiThemeProvider} from "@material-ui/core";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Route render={(props) => (<App
                    {...props}
                />)}
                />
            </BrowserRouter>
        </MuiThemeProvider>
    ,
    document.getElementById('root'));
